import React from 'react';

import type { Route } from '@config/routes';

const RouteComponent = (props: Route): React.ReactElement | null => {
    const { Component, LayoutComponent, ...otherProps } = props;

    return (
        <LayoutComponent pageTitle={otherProps.title}>
            {Component ? <Component {...otherProps} /> : null}
        </LayoutComponent>
    );
};

export { RouteComponent };
