import React from 'react';

import { RouteComponent } from '@lib/routing';

import type { Route } from '@config/routes';

const renderRoutes = (routes: Route[]) => {
    const rendered = routes.map(route => {
        const { children, ...otherRoute } = route;

        return (
            <RouteComponent key={otherRoute.name} {...otherRoute}>
                {children}
            </RouteComponent>
        );
    });

    return rendered;
};

export { renderRoutes };
