import React from 'react';
import styled from 'styled-components';

import { BaseLayoutProvider } from './context';

type BaseLayoutProps = {
    pageTitle: string;
    children: React.ReactNode;
    withBackIcon?: boolean;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background-image: url('/images/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
`;

const BaseLayout = (props: BaseLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    return (
        <BaseLayoutProvider pageTitle={pageTitleProps}>
            <Root>
                <Container>{children}</Container>
            </Root>
        </BaseLayoutProvider>
    );
};

export { BaseLayout };
