import './wdyr';

import ReactDOM from 'react-dom';

import { App } from './app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
