import type { LoadableComponent } from '@loadable/component';
import loadable from '@loadable/component';

import { EmptyLayout, BaseLayout } from '@modules/layout/templates';

import type { RedirectProps, RouteComponentProps } from '@reach/router';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export type OriginRoute = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    LayoutComponent: React.FunctionComponent<any>;
    componentPath?: string;
    redirectTo?: string;
    default?: boolean;
    children?: React.ReactNode;
};

export type Route = Omit<OriginRoute, 'renderForPath' | 'componentPath'> & {
    Component?: LoadableComponent<any>;
};

export type OriginRoutes = Record<string, OriginRoute>;
export type ConfigRoutes = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const originRoutes: OriginRoutes = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: false,
        componentPath: '@pages',
        LayoutComponent: EmptyLayout,
    },

    signUp: {
        name: 'sign-up.index',
        title: 'Регистрация',
        path: '/sign-up',
        private: false,
        componentPath: '@pages/sign-up',
        LayoutComponent: BaseLayout,
    },

    testing: {
        name: 'testing.index',
        title: 'Тестирование',
        path: '/testing',
        private: false,
        componentPath: '@pages/testing',
        LayoutComponent: BaseLayout,
    },
};

const routes = Object.entries(originRoutes).reduce((carry, [key, route]) => {
    let asyncComponent: Route['Component'] = undefined;

    if (route.componentPath) {
        const path = route.componentPath.replace('@', '');

        asyncComponent = loadable(() => import(/* webpackPrefetch: true */ `../${path}`));
    }

    return {
        ...carry,
        [key]: { ...route, Component: asyncComponent },
    };
}, {} as ConfigRoutes);

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/',
        to: routes.index.path,
        default: true,
    },
];

export { routes, redirects };
